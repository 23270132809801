import * as React from 'react';

interface PageProps {
  location: Location;
}
const Page: React.FC<PageProps> = () => {
  React.useEffect(() => {
    location.href = `https://policy.devsisters.com/privacy`;
  }, []);
  return null;
};

export default Page;
